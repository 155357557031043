<div class="page-container">
  <div class="page-title">
    <div id="inspection-result-title" class="flex justify-between">{{ 'InspectionResult.Title' | i18next }}</div>
  </div>
  <loading *ngIf="isLoading"></loading>
  <section *ngIf="!isLoading" class="page-body inspection-result-wrapper">
    <header>
      <div class="details">
        <span class="mr-1">{{ 'General.Date' | i18next }}:</span>
        <span class="mr-4" matTooltip="{{getDateFormat(inspectionResult.header.dateOfInspection)}}"
          >{{getDateFormat(inspectionResult.header.dateOfInspection)}}</span
        >
      </div>
      <div class="details">
        <span class="mr-1">{{ 'InspectionResult.Auditor' | i18next }}:</span>
        <span class="mr-4" matTooltip="{{inspectionResult.header.auditorName}}"
          >{{inspectionResult.header.auditorName}}</span
        >
      </div>

      <div class="details" *ngIf="inspectionResult.header.completedByName">
        <span class="mr-1">{{ 'InspectionResult.CompletedBy' | i18next }}:</span>
        <span class="mr-1" matTooltip="{{inspectionResult.header.completedByName}}"
          >{{inspectionResult.header.completedByName}}</span
        >
      </div>
      <div class="details">
        <span class="mr-1">{{ 'InspectionResult.TargetValue' | i18next }}:</span>
        <span matTooltip="{{inspectionResult.header.targetNumberOfItems}}"
          >{{inspectionResult.header.targetNumberOfItems}}</span
        >
      </div>
      <div class="details" *ngIf="inspectionResult.inspectionReferenceNumber">
        <span class="mr-1">{{ 'General.ReferenceNumber' | i18next }}:</span>
        <span matTooltip="{{inspectionResult.inspectionReferenceNumber}}"
          >{{inspectionResult.inspectionReferenceNumber}}</span
        >
      </div>
    </header>
    <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false">
      <mat-tab class="details" label="{{'InspectionResult.Tabs.TestDetails' | i18next}}">
        <div class="pdf-button">
          <button mat-raised-button color="accent" (click)="previewPdf()">
            {{ 'InspectionResult.PreviewPDF' | i18next }}
          </button>
        </div>
        <app-test-details [inspectionResult]="inspectionResult"></app-test-details>
      </mat-tab>
      <mat-tab
        bodyClass="gallery-overview-tab-wrapper"
        label="{{'InspectionResult.Tabs.GalleryOverview.Title' | i18next}}"
      >
        <app-gallery-overview [inspectionResultDetails]="inspectionResultDetails"></app-gallery-overview>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>
