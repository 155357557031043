import jsPDF from "jspdf";
import { InspectionResultDetailsDto } from "../models/inspection-result";
import { TranslationService } from "./translation.service";
import i18next from "i18next";

export class ExportPdfBaseService {
  readonly inspectionDetailsIncrement = 30;

  protected jsPdf: jsPDF;
  protected currentPositionX: number;
  protected currentPositionY: number;
  protected currentIncrement: number;
  protected inspectionResultTitleFontSize: number;
  protected inspectionDetailsFontSize: number;
  protected inspectionDate: string | null;
  protected auditorName: string;
  protected completedByName: string;
  protected targetValue: number;
  protected branchName: string;
  protected inspectionReferenceNumber: string;

  constructor(
    protected inspectionResultDetails: InspectionResultDetailsDto,
    protected translationService: TranslationService
  ) {
    this.jsPdf = new jsPDF();
    this.currentPositionX = 10;
    this.currentPositionY = 20;
    this.currentIncrement = 20;
    this.inspectionResultTitleFontSize = 20;
    this.inspectionDetailsFontSize = 14;
    this.auditorName = inspectionResultDetails.auditorName;
    this.completedByName = inspectionResultDetails.completedByName;
    this.targetValue = inspectionResultDetails.targetNumberOfItems;
    this.branchName = inspectionResultDetails.branchName;
    this.inspectionDate = this.inspectionResultDetails.dateOfInspection;
    this.inspectionReferenceNumber = inspectionResultDetails.inspectionReferenceNumber;
  }

  protected addInspectionResultTitle(): void {
    this.jsPdf.setFontSize(this.inspectionResultTitleFontSize);
    this.jsPdf.addFont("./../../assets/fonts/Roboto/Roboto-Regular.ttf", "Roboto", "normal");
    this.jsPdf.setFont("Roboto");

    const inspectionResultTitle = this.translationService.translate("InspectionResult.Title");

    const pageWidth = this.jsPdf.internal.pageSize.getWidth();
    const titleText = (pageWidth - this.jsPdf.getTextWidth(inspectionResultTitle)) / 2;
    this.jsPdf.text(inspectionResultTitle, titleText, this.currentPositionY);
    this.currentPositionY += this.inspectionDetailsIncrement;
    this.currentPositionX += this.currentIncrement;
  }

  protected addInspectionDetails(): void {
    this.jsPdf.setFontSize(this.inspectionDetailsFontSize);

    const inspectionDateText = `${this.translationService.translate("General.Date")}: ${this.inspectionDate}`;
    const auditorText = `${this.translationService.translate("InspectionResult.Auditor")}: ${this.auditorName}`;
    const completedByNameText = `${this.translationService.translate("InspectionResult.CompletedBy")}: ${
      this.completedByName
    }`;
    const targetValueText = `${this.translationService.translate("InspectionResult.TargetValue")}: ${this.targetValue}`;
    const inspectionReferenceNumberText = `${this.translationService.translate(
      "General.ReferenceNumber"
    )}: ${this.inspectionReferenceNumber}`;
    const pageWidth = this.jsPdf.internal.pageSize.getWidth();
    const marginX = 10;
    const maxWidth = pageWidth - marginX * 2;
    let currentText = inspectionDateText;

    this.jsPdf.text(currentText, this.currentPositionX, this.currentPositionY);

    if (this.jsPdf.getTextWidth(`${currentText} | ${auditorText}`) > maxWidth) {
      this.currentPositionY += this.currentIncrement;
      currentText = auditorText;
    } else {
      currentText += ` | ${auditorText}`;
    }

    this.jsPdf.text(currentText, this.currentPositionX, this.currentPositionY);

    if (this.completedByName !== null) {
      if (this.jsPdf.getTextWidth(`${currentText} | ${completedByNameText}`) > maxWidth) {
        this.currentPositionY += this.currentIncrement;
        currentText = completedByNameText;
      } else {
        currentText += ` | ${completedByNameText}`;
      }
    }

    this.jsPdf.text(currentText, this.currentPositionX, this.currentPositionY);

    if (this.jsPdf.getTextWidth(`${currentText} | ${targetValueText}`) > maxWidth) {
      this.currentPositionY += this.currentIncrement;
      currentText = targetValueText;
    } else {
      currentText += ` | ${targetValueText}`;
    }

    this.jsPdf.text(currentText, this.currentPositionX, this.currentPositionY);
    if (this.inspectionReferenceNumber !== null) {
      if (this.jsPdf.getTextWidth(`${currentText} | ${inspectionReferenceNumberText}`) > maxWidth) {
        this.currentPositionY += this.currentIncrement;
        currentText = inspectionReferenceNumberText;
      } else {
        currentText += ` | ${inspectionReferenceNumberText}`;
      }
    }

    this.jsPdf.text(currentText, this.currentPositionX, this.currentPositionY);
    this.currentPositionY += this.currentIncrement;
  }
}
