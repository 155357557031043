<form [formGroup]="filterDashboardForm" (ngSubmit)="applyFilters()" class="filter-dashboard">
  <h1 mat-dialog-title>{{ "General.Filters.Filter" | i18next }}</h1>
  <loading *ngIf="isLoading" [spinnerSize]="64"></loading>
  <div mat-dialog-content class="form-container">
    <mat-form-field appearance="fill" class="width-100">
      <mat-label>{{ "Dashboard.Inspection.Titles.Auditors" | i18next }}</mat-label>
      <div class="flex items-baseline">
        <mat-select formControlName="auditors">
          <mat-option *ngFor="let auditor of auditorsList" [value]="auditor">{{ auditor }}</mat-option>
        </mat-select>
        <app-filter-clear-button
          [isVisible]="!!filterDashboardForm.get('auditors')?.value"
          (clear)="clearSelection('auditors')"
        ></app-filter-clear-button>
      </div>
    </mat-form-field>

    <mat-form-field appearance="fill" class="width-100">
      <mat-label>{{ "CreateTestInspection.Labels.SelectDate" | i18next }}</mat-label>
      <div class="flex items-baseline">
        <mat-date-range-input [rangePicker]="picker" formGroupName="dateOfInspection">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <app-filter-clear-button
          [isVisible]="checkDateOfInspection()"
          (clear)="clearSelection('dateOfInspection')"
        ></app-filter-clear-button>
      </div>
    </mat-form-field>

    <mat-form-field appearance="fill" class="width-100">
      <mat-label>{{ "Dashboard.Inspection.Titles.Status" | i18next }}</mat-label>
      <div class="flex items-baseline">
        <mat-select formControlName="status">
          <mat-option *ngFor="let status of statusList" [value]="status">{{
            "Dashboard.Inspection.Statuses." + status.toString() | i18next
          }}</mat-option>
        </mat-select>
        <app-filter-clear-button
          [isVisible]="!!filterDashboardForm.get('status')?.value"
          (clear)="clearSelection('status')"
        ></app-filter-clear-button>
      </div>
    </mat-form-field>

    <mat-form-field appearance="fill" class="width-100">
      <mat-label>{{ "Dashboard.Inspection.Titles.Types" | i18next }}</mat-label>
      <div class="flex items-baseline">
        <mat-select formControlName="types">
          <mat-option *ngFor="let type of typesList" [value]="type">{{
            "Dashboard.Inspection.Types." + type.toString() | i18next
          }}</mat-option>
        </mat-select>
        <app-filter-clear-button
          [isVisible]="!!filterDashboardForm.get('types')?.value"
          (clear)="clearSelection('types')"
        ></app-filter-clear-button>
      </div>
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="form-actions">
    <doka-button color="clear" (click)="onClose()" class="main-button"> {{ "General.Cancel" | i18next }}</doka-button>
    <button mat-raised-button color="accent" type="submit" [disabled]="isFormEmpty()">
      {{ "General.Filters.ApplyFilter" | i18next }}
    </button>
  </div>
</form>
