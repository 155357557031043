import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-filter-clear-button",
  templateUrl: "./filter-clear-button.component.html",
  styleUrls: ["./filter-clear-button.component.scss"],
})
export class FilterClearbuttonComponent {
  @Input() label: string = "";
  @Input() isVisible: boolean = false;
  @Output() clear = new EventEmitter<void>();

  onClear(): void {
    this.clear.emit();
  }
}
