import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { InspectionModel } from "src/app/models/inspections.model";
import { FilterDataDashboardService } from "src/app/services/filter-data-dashboard-service";

@Component({
  selector: "app-filter-dashboard",
  templateUrl: "./filter-dashboard.component.html",
  styleUrls: ["./filter-dashboard.component.scss"],
})
export class FilterDashboardComponent {
  filterDashboardForm: FormGroup = new FormGroup({});
  isLoading = false;
  auditorsList: string[] = [];
  statusList: string[] = [];
  typesList: string[] = [];
  filterFormInitialValues = {
    auditors: [],
    dateOfInspection: this.fb.group({
      start: [],
      end: [],
    }),
    status: [],
    types: [],
  };

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: InspectionModel[],
    public dialogRef: MatDialogRef<FilterDashboardComponent>,
    private filterDataDashboardService: FilterDataDashboardService
  ) {}

  ngOnInit(): void {
    const savedFilters = this.filterDataDashboardService.getFilters() || this.filterFormInitialValues;
    this.filterDashboardForm = this.fb.group({
      auditors: savedFilters.auditors,
      dateOfInspection: this.fb.group({
        start: savedFilters.dateOfInspection.start,
        end: savedFilters.dateOfInspection.end,
      }),
      status: savedFilters.status,
      types: savedFilters.types,
    });
    this.auditorsList = this.filterDataDashboardService.getAuditorsList(this.data);
    this.statusList = this.filterDataDashboardService.getStatusList(this.data);
    this.typesList = this.filterDataDashboardService.getTypesList(this.data);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  checkDateOfInspection(): boolean {
    return (
      !!this.filterDashboardForm.get("dateOfInspection")?.value.start ||
      !!this.filterDashboardForm.get("dateOfInspection")?.value.end
    );
  }

  clearSelection(controlName: string): void {
    const control = this.filterDashboardForm.get(controlName);
    if (control) {
      control.reset();
    }
  }

  isFormEmpty(): boolean {
    const formValue = this.filterDashboardForm.value;
    return (
      !formValue.auditors &&
      !formValue.dateOfInspection.start &&
      !formValue.dateOfInspection.end &&
      !formValue.status &&
      !formValue.types
    );
  }

  applyFilters(): void {
    const filters = {
      auditorName: this.filterDashboardForm.value.auditors,
      dateOfInspection: this.filterDashboardForm.value.dateOfInspection,
      inspectionStatusId: this.filterDashboardForm.value.status,
      inspectionTypeId: this.filterDashboardForm.value.types,
    };
    this.filterDataDashboardService.setFilters(filters);
    this.dialogRef.close(filters);
  }
}
