<div class="page-container">
  <div class="page-title">
    <div class="flex justify-between">
      {{ 'Dashboard.Name' | i18next }}
      <button mat-raised-button color="accent" *ngIf="isAuditor" (click)="openDialog()">
        {{ 'Dashboard.NewTest' | i18next }}
      </button>
    </div>
  </div>
  <section class="dashboard-body">
    <loading *ngIf="isLoading"></loading>
    <section class="dashboard-list header">
      <div class="cell bold">{{ 'Dashboard.Inspection.Titles.Auditors' | i18next }}</div>
      <div class="cell bold">{{ 'General.ReferenceNumber' | i18next }}</div>
      <div class="cell bold">{{ 'Dashboard.Inspection.Titles.Scheduled' | i18next }}</div>
      <div class="cell bold">{{ 'Dashboard.Inspection.Titles.Progress' | i18next }}</div>
      <div class="cell bold">{{ 'Dashboard.Inspection.Titles.Status' | i18next }}</div>
      <div class="cell bold">{{ 'Dashboard.Inspection.Titles.Types' | i18next }}</div>
      <div class="cell bold">{{ 'Dashboard.Inspection.Titles.Kpi' | i18next }}</div>
      <doka-button
        icon="filter_alt"
        [iconOnly]="true"
        class="cell bold button-icon width-100"
        mat-raised-button
        color="clear"
        (click)="openFilterDialog()"
      >
        {{ 'General.Filters.Filter' | i18next }}</doka-button
      >
      <doka-button
        icon="filter_alt_off"
        [iconOnly]="true"
        class="cell bold button-icon border-none ml-0 width-100"
        mat-raised-button
        color="clear"
        (click)=" resetFilters()"
        [disabled]="!isFilterActive"
      >
        {{ 'General.Filters.ClearFilter' | i18next }}</doka-button
      >
    </section>
    <section class="dashboard-list" *ngFor="let inspection of inspections">
      <div class="cell bold">{{inspection.auditor.name || inspection.auditor.email}}</div>
      <div class="cell">{{inspection.referenceNumber || "-"}}</div>
      <div class="cell bold">{{getDateFormat(inspection.dateOfInspection)}}</div>
      <div class="cell">{{inspection.progressPercentage}}%</div>
      <div class="cell">{{ 'Dashboard.Inspection.Statuses.' + inspection.inspectionStatusId.toString()| i18next }}</div>
      <div class="cell bold">
        {{ 'Dashboard.Inspection.Types.' + inspection.inspectionTypeId .toString()| i18next }}
      </div>
      <div class="cell">{{inspection.kpiPercentage}}%</div>
      <div class="cell text-right">
        <doka-button
          (click)="goTo('inspection-result', inspection.inspectionId)"
          size="medium"
          *ngIf="inspection.isSetupFinished"
          class="main-button width-100"
          color="primary"
        >
          {{ 'Dashboard.View' | i18next }}</doka-button
        >
        <doka-button
          (click)="goTo('test-sheet', inspection.inspectionId)"
          size="medium"
          *ngIf="!inspection.isSetupFinished"
          class="main-button width-100"
          color="primary"
        >
          {{ 'Dashboard.Edit' | i18next }}</doka-button
        >
      </div>
      <div class="cell flex justify-center">
        <doka-button
          *ngIf="inspection.canDelete"
          color="clear"
          icon="delete"
          [iconOnly]="true"
          class="button-icon"
          (click)="deleteInspection(inspection.inspectionId, getDateFormat(inspection.dateOfInspection))"
        ></doka-button>
      </div>
    </section>
  </section>
  <section *ngIf="inspections.length < 1 && !isLoading" class="empty-state">
    <img src="../../../assets/icons/no-data.svg" alt="no-data" class="mb-2" />
    <span class="description">
      {{ 'Dashboard.DefaultEmptyMessage' | i18next }}
      <span *ngIf="isAuditor"
        >{{ 'Dashboard.AuditorOnlyEmptyMessage' | i18next}}
        <a (click)="openDialog()">{{ 'General.Here' | i18next }}</a>.</span
      >
    </span>
  </section>
</div>
