<app-download-button-pdf
  [isLoading]="isLoading"
  [hasDataAvailable]="hasDataAvailable"
  [downloadAsPdf]="downloadAsPdf.bind(this)"
></app-download-button-pdf>

<div class="failures-list-container gallery-overview">
  <loading *ngIf="isLoading"></loading>
  <div class="failures-list" *ngFor="let failureReason of failureReasonsList">
    <h2>
      {{ "ConfigurationList.InspectionGroup." + failureReason.inspectionGroupIdentifier | i18next }}
    </h2>
    <div *ngFor="let inspectionItem of failureReason.inspectionItems">
      <h3>{{ inspectionItem.articleIdentifier }} - {{ inspectionItem.name }}</h3>
      <div class="failure-image-container">
        <div *ngFor="let inspectionItemFailureReason of inspectionItem.inspectionItemFailureReasons">
          <h4>
            {{ "InspectionResult.ItemFailureReasons.List." + inspectionItemFailureReason.failureReasonId | i18next }}
          </h4>
          <div *ngFor="let inspectionItemFailureReasonId of inspectionItemFailureReason.inspectionItemFailureReasonIds">
            <app-image-size
              [inspectionItemImageDetails]="{
                inspectionItemFailureReasonId: inspectionItemFailureReasonId,
                failureReasonId: inspectionItemFailureReason.failureReasonId,
                inspectionItemName: inspectionItem.name,
                articleIdentifier: inspectionItem.articleIdentifier
              }"
            ></app-image-size>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
