<div class="page-container">
  <div class="page-title">
    <div class="flex justify-between test-sheet-header">
      <div>{{ 'TestSheet.Title' | i18next }}</div>
      <section class="action-buttons">
        <doka-button color="clear" icon="history" [iconOnly]="false" class="main-button" (click)="resetConfiguration()"
          >{{ 'TestSheet.ResetConfiguration' | i18next }}</doka-button
        >
        <doka-button color="clear" icon="error" [iconOnly]="false" class="main-button" (click)="goToConfiguration()">
          {{ 'TestSheet.ConfigurationList' | i18next }}</doka-button
        >
        <button mat-raised-button color="accent" (click)="export()">{{ 'TestSheet.Export' | i18next }}</button>
      </section>
    </div>
    <div class="page-subtitle">
      <span *ngIf="inspection?.inspectionReferenceNumber" class="mr-6"
        >{{ 'General.ReferenceNumber' | i18next }}: {{inspection?.inspectionReferenceNumber}}
      </span>
      <span class="mr-6">{{ 'TestSheet.TotalPieces' | i18next }} {{totalPieces}} </span>{{ 'TestSheet.AvailablePieces' |
      i18next }}: {{availablePieces}}
    </div>
  </div>
  <div class="page-body test-sheet-body">
    <loading *ngIf="isLoading"></loading>
    <header class="header">
      <div></div>
      <div>{{ 'TestSheet.TestArticles' | i18next }}</div>
      <div class="text-center">{{ 'TestSheet.Quantity' | i18next }}</div>
      <div class="text-center">{{ 'TestSheet.Delete' | i18next }}</div>

      <button
        mat-raised-button
        class="text-center open-dialog"
        color="primary"
        (click)="openFocusItemDialog()"
        [disabled]="availablePieces === 0"
      >
        {{ "TestSheet.AddFocusItem" | i18next }}
      </button>
    </header>
    <test-items
      *ngFor="let inspectionGroup of inspection?.inspectionGroups"
      [piecesData]="{inspectionGroup: inspectionGroup, inspectionId: inspection?.inspectionId,availablePieces: availablePieces, totalPieces: totalPieces}"
      (deleteFocusItemEvent)="deleteFocusItem($event)"
      (deleteItemEvent)="deleteItem($event)"
    ></test-items>
  </div>
</div>
