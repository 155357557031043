import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { EventBusActions } from "src/app/models/event-bus-actions";
import { ExportGalleryOverviewDto } from "src/app/models/export-pdf.model";
import { InspectionResultDetailsDto, InspectionResultGallery } from "src/app/models/inspection-result";
import { EmitEvent, EventBusService } from "src/app/services/event-bus.service";
import { ExportPdfService } from "src/app/services/export-pdf.service";
import { GalleryService } from "src/app/services/gallery.service";
@Component({
  selector: "app-inspection-groups-group-gallery",
  templateUrl: "./inspection-groups-group-gallery.component.html",
})
export class InspectionGroupsGroupGalleryComponent implements OnInit {
  @Input() inspectionResultDetails!: InspectionResultDetailsDto;
  isLoading = false;
  failureReasonsList: InspectionResultGallery[] = [];
  eventBusSubscription: Subscription = new Subscription();

  constructor(private galleryService: GalleryService, private exportPdfService: ExportPdfService, protected eventBusService: EventBusService) { }

  ngOnInit(): void {
    this.getGallery();
    this.eventBusSubscription.add(
      this.eventBusService.on(EventBusActions.downloadPdf, (isLoading: boolean) => {
        this.isLoading = isLoading;
      }));
  }


  get hasDataAvailable() {
    return this.failureReasonsList.length > 0;
  }

  getGallery() {
    this.isLoading = true;
    this.galleryService
      .getInspectionGalleryGroupedByInspectionGroup(this.inspectionResultDetails.inspectionId)
      .subscribe({
        next: (data) => {
          this.isLoading = false;
          this.failureReasonsList = data;
        },
        error: () => (this.isLoading = false),
      });
  }

  downloadAsPdf() {
    this.eventBusService.emit(
      new EmitEvent(EventBusActions.downloadPdf, true)
    );
    const exportGalleryOverview: ExportGalleryOverviewDto = {
      failureReasonsList: this.failureReasonsList,
      inspectionResultDetails: this.inspectionResultDetails,
    };
   setTimeout(() => { this.exportPdfService.galleryOverviewGeneratePdf(exportGalleryOverview);}, 500);
  }
}
